<template>
  <section
    id="main-howellcare"
    class="overflow-hidden"
  >
    <v-img
      :min-height="minHeight"
      :src="require('@/assets/main3.jpg')"
      height="800"
      class="white--text"
      :class="classConf"
    >
      <v-img
        class="mt-16 pt-16"
        :src="require('@/assets/RH_ROBOTICS_logo.png')"
        height="80"
        contain
        :class="classConf2"
      />
    </v-img>
  </section>
</template>

<script>
  export default {
    name: 'SectionMainHowellcare',
    provide: {
      theme: { isDark: true },
    },
    data () {
      return {
        classConf: '',
        classConf2: '',
        videoPath: require('@/assets/main_howellcare_8.mp4'),
        videoPath480p: require('@/assets/main_howellcare_8.mp4'),
        posterPath: require('@/assets/main_howellcare_poster.jpg'),
      }
    },

    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
    },
    mounted () {
      setTimeout(() => {
        this.classConf = 'enlarge'
        this.classConf2 = 'enlarge2'
      }, 1000)
    },
    methods: {
      fnGoToAbout () {
        this.$router.push('/about')
      },
    },
  }
</script>

<style lang="sass">
  .enlarge
    transform: scale(1.2) translate(0px,-50px)
    transition: transform 3s
  .enlarge2
    transform: scale(1.2, 1.2) translate(0px,180px)
    transition: transform 5s
</style>
